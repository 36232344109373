






















import Vue from "vue";
import ConferenceContent from "@/components/templates/ConferenceContent.vue";
import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  name: "template_main",

  components: {
    VaButton,
    ConferenceContent
  }
});
