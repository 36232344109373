
























import Vue from "vue";

export default Vue.extend({
  name: "template_conference"
});
