var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "conference-content",
    {
      scopedSlots: _vm._u([
        {
          key: "topContent",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "div",
                    { staticClass: "secondary-outline rounded pa-4 mr-4" },
                    [_vm._v("some cani & gatti")]
                  ),
                  _c("va-button", {
                    staticClass: "mr-4",
                    attrs: { type: "square", color: "secondary", cta: "I" }
                  }),
                  _c("div", { staticClass: "flex-grow-1 mr-4 text-center" }, [
                    _vm._v("some top content")
                  ]),
                  _c("va-button", {
                    staticClass: "mr-4",
                    attrs: { cta: "button" }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "leftContent",
          fn: function() {
            return [_vm._v("some left")]
          },
          proxy: true
        },
        {
          key: "rightContent",
          fn: function() {
            return [_vm._v("some right")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" content "),
      _c("br"),
      _vm._v("content"),
      _c("br"),
      _c("router-link", { attrs: { to: "/design/maincontent" } }, [
        _vm._v("main view")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/design/authcontent" } }, [
        _vm._v("auth view")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/design/conferencecontent" } }, [
        _vm._v("conference view")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/design" } }, [
        _vm._v("back to design system")
      ]),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }