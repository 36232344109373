var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapperCentered" }, [
    _c(
      "div",
      { staticClass: "floatingContent floatingContent__top pt-4 px-4" },
      [_vm._t("topContent")],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "wrapperCentered pa-0 d-flex justify-center align-center flex-grow-1"
      },
      [
        _c(
          "div",
          { staticClass: "floatingContent floatingContent__left pl-4" },
          [_vm._t("leftContent")],
          2
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-center align-center wrapperCentered" },
          [_c("div", [_vm._t("default")], 2)]
        ),
        _c(
          "div",
          { staticClass: "floatingContent floatingContent__right pr-4" },
          [_vm._t("rightContent")],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }